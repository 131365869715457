import { queryAllByClass, getClosestByClass } from "../shared/util";
import Keys from "../shared/pubsub-keys";
import PubSub from "pubsub-js";
import Axios from "axios/dist/axios";
import striptags from "striptags";
import {
  getSearchServiceUrl,
  getSplitEnvironmentFromSiteMode,
  searchTrackingEvent,
  getScopeFromShopId,
  getSessionTemporaryId,
} from "./search-utils";

let suggestContainer,
  searchContainer,
  searchField,
  searchButton,
  buffer,
  currentSearch,
  productsListFocusable,
  searchButtonClickHandler;
let productActiveIndex = 0;
let delayTimer;
const d = document;
const EXECUTING_CLASS = "executing";
const SEARCH_SUGGEST_THRESHOLD = 2;
const SHOW_CLASS = "show";
const HIDE_HIDDEN_PILLS_CLASS = "hide-hidden-pills";
const HIDDEN_PILL_CLASS = "hidden-pill";
const SEARCH_DELAY_MILLISECONDS = 300;
const environment = getSplitEnvironmentFromSiteMode(window.site_mode);
const temporaryId = getSessionTemporaryId();
const shopScope = getScopeFromShopId(window.shop_id);
const shopType = window.shop_type;

function init() {
  searchContainer = d.querySelector(".js-search-form");
  suggestContainer = searchContainer.querySelector(".js-search-suggest");
  searchField = searchContainer.querySelector(".js-search-field");
  searchButton = searchContainer.querySelector(".js-search-submit");

  PubSub.subscribe(Keys.SEARCH_SUGGEST, getSearchResult);

  // Hide search suggest on click outside the pop over
  d.addEventListener("click", (event) => {
    if (
      event.target !== suggestContainer &&
      !suggestContainer.contains(event.target) &&
      event.target !== searchField
    ) {
      hideSuggestion();
    }
  });
}

function useSolrSearch(query) {
  Axios.get(`/solr/ajax_search.php?type=json&limit=8&q=${query}`).then((r) => {
    processResult(r.data);
  });
}

function useSearchService(env, query) {
  const url = getSearchServiceUrl(env);
  Axios.post(url, {
    term: query,
    scope: shopScope,
    limit: 8,
    user: {
      temporaryId,
    },
  })
    .then((result) => {
      processResult(result.data);
    })
    .catch(() => {
      const encodedQuery = encodeURIComponent(query);
      useSolrSearch(encodedQuery);
    });
}

function performSearch(trimmedQuery, encodedQuery) {
  if (shopType === "b2b") {
    useSolrSearch(encodedQuery);
  } else {
    useSearchService(environment, trimmedQuery);
    
    searchTrackingEvent("search_box_show", "show", {
      is_relewise: true,
      environment,
      shop: window.shop_id,
      type: "search_box_show",
      term: currentSearch,
    });
  }
}

function getSearchResult(key, query) {
  const trimmedQuery = query.trim();
  const encodedQuery = encodeURIComponent(trimmedQuery);

  buffer = query;

  // reset if search string is shorter than threshold
  if (trimmedQuery.length < SEARCH_SUGGEST_THRESHOLD) {
    if (suggestionShown()) {
      clearSuggestion();
    }
    setExecuting(false);
    searchButton.href = "#";
    return;
  }

  currentSearch = query;
  setExecuting();

  // Adding timeout to debounce search requests
  clearTimeout(delayTimer);
  delayTimer = setTimeout(
    performSearch,
    SEARCH_DELAY_MILLISECONDS,
    trimmedQuery,
    encodedQuery
  );
}

function setupArrowNavigation() {
  productActiveIndex = 0;
  productsListFocusable = suggestContainer.querySelectorAll(".js-focusable");
  let productsContainer = suggestContainer.querySelector(".js-products-list");

  // Navigate to product list on down arrow in search field
  searchField.addEventListener("keydown", (e) => {
    if (suggestionShown() && e.keyCode === 40) {
      e.preventDefault();
      if (productsListFocusable.length)
        productsListFocusable[productActiveIndex].focus();
    }
  });

  // Navigate up and down in the product list
  productsContainer.addEventListener("keydown", (e) => {
    if (!(e.keyCode === 38 || e.keyCode === 40)) return;
    e.preventDefault();

    if (e.keyCode === 40) {
      productActiveIndex =
        productsListFocusable.length > productActiveIndex + 1
          ? productActiveIndex + 1
          : 0;
      productsListFocusable[productActiveIndex].focus();
    }
    if (e.keyCode === 38) {
      productActiveIndex =
        productActiveIndex > 0
          ? productActiveIndex - 1
          : productsListFocusable.length - 1;
      productsListFocusable[productActiveIndex].focus();
    }
  });
}

function processResult(result) {
  // If search field content has changed since request was excuted, request new results instead of rendering
  if (currentSearch !== buffer) {
    getSearchResult(null, buffer);
    return;
  }

  clearSuggestion();
  clearProcessResultOnSearchButtonClick();

  if (!result.searchAvailable) {
    renderSearchUnavailable(result.error_message);
    setupProcessResultOnSearchButtonClick(result);
  } else if (result.totalCount === 0) {
    renderZeroResult();
  } else {
    renderResult(result);
    setupPillToggleEvents();
    setupArrowNavigation();
  }

  setExecuting(false);
  showSuggestion();
}

function renderResult(resultJson) {
  let fragment = d.createDocumentFragment(),
    resultGroupsOuter = d.createElement("div"),
    footer = d.createElement("div"),
    seeAll = d.createElement("a");
  footer.classList.add("search__suggest__footer");
  seeAll.classList.add("search__suggest__see-all");
  seeAll.href = resultJson.seeAllUrl;
  const { searchSeeAllLabel, searchResultLabel } = window.wordbookObject;
  const seeAllButtonText = `${searchSeeAllLabel} ${searchResultLabel}`

  seeAll.appendChild(d.createTextNode(seeAllButtonText));
  seeAll.onclick = () => {
    const payload = {
      is_relewise: true,
      environment,
      shop: window.shop_id,
      type: "see_all_results",
      results_quantity: resultJson.totalCount,
      term: currentSearch,
    };
    searchTrackingEvent("search_see_all_results_click", "click", payload);
  };

  resultGroupsOuter.classList.add("search__suggest__resultgroups-outer");
  fragment.appendChild(resultGroupsOuter);

  // Render and append left side product list
  resultGroupsOuter.appendChild(productsTemplate(resultJson.resultGroups));

  // Render and append right side result groups (ie. brands)
  resultGroupsOuter.appendChild(resultGroupTemplate(resultJson));
  footer.appendChild(seeAll);
  fragment.appendChild(footer);
  suggestContainer.appendChild(fragment);
  searchButton.href = resultJson.seeAllUrl;
  searchField.addEventListener("keydown", (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      
      window.location.href = resultJson.seeAllUrl;
    }
  }
  );
}

function productsTemplate(resultGroupsArg) {
  let groupedProducts = resultGroupsArg.products.products_by_categories,
    productsMeta = resultGroupsArg.products.products_by_categories_count,
    resultGroups = d.createElement("div");
  resultGroups.classList.add("search__suggest__resultgroups"); // IE doesnt support adding multiple classes in one go..
  resultGroups.classList.add("js-products-list");

  Object.keys(groupedProducts).forEach((key, groupIndex) => {
    let group = d.createElement("div"),
      groupHeader = d.createElement("div"),
      groupHeading = d.createElement("a"),
      groupItemsList = d.createElement("ul");

    group.classList.add("resultgroup");
    groupHeader.classList.add("resultgroup__header");
    groupHeading.appendChild(d.createTextNode(key));
    if (productsMeta[key]) {
      if (productsMeta[key].count) {
        groupHeading.appendChild(
          d.createTextNode(` (${productsMeta[key].count})`)
        );
      }
      groupHeading.href = `/search_result/?keywords=${currentSearch}&categories_id=${productsMeta[key].id}`;
    }
    groupHeading.classList.add("resultgroup__heading");
    groupHeading.onclick = () => {
      const payload = {
        is_relewise: true,
        environment,
        shop: window.shop_id,
        type: "product_group_header",
        name: key,
        index: groupIndex,
        term: currentSearch,
      };
      searchTrackingEvent(
        "search_product_group_header_click",
        "click",
        payload
      );
    };
    groupItemsList.classList.add("resultgroup__items");
    groupHeader.appendChild(groupHeading);
    group.appendChild(groupHeader);
    group.appendChild(groupItemsList);

    groupedProducts[key].forEach((product, productIndex) => {
      let item = d.createElement("li"),
        itemLink = d.createElement("a"),
        itemThumb = d.createElement("img"),
        itemThumbContainer = d.createElement("div"),
        itemTitle = d.createElement("div"),
        itemOriginalPrice = d.createElement("span"),
        itemPrice = d.createElement("span");
      item.classList.add("resultgroup__item");
      itemLink.tabIndex = -1;
      itemLink.href = product.url;
      itemLink.classList.add("js-focusable");
      itemLink.onclick = () => {
        const payload = {
          is_relewise: true,
          environment,
          shop: window.shop_id,
          type: "product_item",
          name: striptags(product.name),
          sku: product.sku,
          product_index: productIndex,
          term: currentSearch,
          product_group_header_name: key,
          product_group_header_index: groupIndex,
        };
        searchTrackingEvent("search_product_item_click", "click", payload);
      };
      itemThumbContainer.classList.add("resultgroup__item__thumb-container");
      itemThumb.classList.add("resultgroup__item__thumb");
      itemThumb.src = product.product_thumb;
      itemTitle.classList.add("resultgroup__item__titel");
      itemTitle.innerHTML = product.name;
      if (product.hasOwnProperty("price")) {
        itemOriginalPrice.classList.add("resultgroup__item__orig-price");
        itemOriginalPrice.innerHTML =
          product.originalPrice && product.price !== product.originalPrice
            ? product.originalPrice
            : "";
        itemPrice.classList.add("resultgroup__item__price");
        itemPrice.innerHTML = product.price;
        itemLink.appendChild(itemOriginalPrice);
        itemLink.appendChild(itemPrice);
      }
      itemThumbContainer.appendChild(itemThumb);
      itemLink.appendChild(itemThumbContainer);
      itemLink.appendChild(itemTitle);
      item.appendChild(itemLink);
      groupItemsList.appendChild(item);
    });
    resultGroups.appendChild(group);
  });
  return resultGroups;
}

function resultGroupTemplate(result) {
  // Create outer group wrap to contain all groups
  let newGroupWrap = d.createElement("div");
  newGroupWrap.classList.add("search__suggest__resultgroups");

  // Populate wrap with groups
  result.resultGroupsSortOrder.forEach((groupTitle) => {
    let newGroup;

    switch (groupTitle) {
      case "categories":
      case "seo_pages":
        newGroup = createPillList(result, groupTitle);
        if (newGroup) {
          newGroupWrap.appendChild(newGroup);
        }
        break;
      case "brands":
        newGroup = createPillList(result, groupTitle, 15);
        if (newGroup) {
          newGroupWrap.appendChild(newGroup);
        }
        break;
    }
  });
  return newGroupWrap;
}

function createPillList(result, groupTitle, maxItems = 0) {
  let groupData = result.resultGroups[groupTitle];
  if (!groupData || !groupData.matches || !groupData.matches.length) return;

  // Create group section elements
  let group = d.createElement("div"),
    groupHeader = d.createElement("div"),
    groupHeading = d.createElement("span"),
    groupItemsList = d.createElement("ul");
  group.classList.add("resultgroup");
  group.classList.add("js-resultgroup");
  group.classList.add(HIDE_HIDDEN_PILLS_CLASS);
  groupHeader.classList.add("resultgroup__header");
  groupHeading.classList.add("resultgroup__heading");
  groupItemsList.classList.add("resultgroup__items");

  // Set group header
  groupHeading.innerHTML = groupData.title;
  groupHeader.appendChild(groupHeading);
  group.appendChild(groupHeader);

  // Set group item list
  groupData.matches.forEach((item, index) => {
    let listItem = d.createElement("li"),
      itemLink = d.createElement("a");
    listItem.classList.add("resultgroup__pill");
    if (index >= maxItems && maxItems !== 0) {
      listItem.classList.add("js-resultgroup-hidden-pill");
      listItem.classList.add(HIDDEN_PILL_CLASS);
    }
    itemLink.href = item.url;
    itemLink.innerHTML = item.name;
    itemLink.onclick = () => {
      const payload = {
        is_relewise: true,
        environment,
        shop: window.shop_id,
        type: groupTitle,
        name: striptags(item.name),
        index,
        term: currentSearch,
      };
      searchTrackingEvent(`search_${groupTitle}_item_click`, "click", payload);
    };
    listItem.appendChild(itemLink);
    groupItemsList.appendChild(listItem);
  });
  group.appendChild(groupItemsList);

  // Add toggle option
  if (groupData.matches.length > maxItems && maxItems !== 0) {
    let toggleBtn = d.createElement("div"),
      toggleBtnText = d.createElement("span");
    toggleBtnText.classList.add("resultgroup__pill-toggle");
    toggleBtnText.classList.add("js-toggle-pills-btn");
    toggleBtnText.innerHTML = window.wordbookObject.showMore;
    toggleBtn.appendChild(toggleBtnText);
    group.appendChild(toggleBtn);
  }
  return group;
}

function setupPillToggleEvents() {
  let pillToggleBtns = queryAllByClass("js-toggle-pills-btn");

  pillToggleBtns.forEach((button) => {
    button.addEventListener("click", toggleHiddenPills);
  });
}

function toggleHiddenPills(event) {
  let toggleBtn = event.target;
  let groupParent = getClosestByClass("js-resultgroup", toggleBtn);

  groupParent.classList.toggle(HIDE_HIDDEN_PILLS_CLASS);
  toggleBtn.classList.toggle("js-show-hidden-pills");

  if (toggleBtn.classList.contains("js-show-hidden-pills")) {
    toggleBtn.innerHTML = window.wordbookObject.showLess;
  } else {
    toggleBtn.innerHTML = window.wordbookObject.showMore;
  }
}

function renderZeroResult() {
  let zeroSpan = d.createElement("span");
  zeroSpan.classList.add("search__suggest-zero");
  zeroSpan.innerHTML = window.wordbookObject.searchNoResultLabel;
  suggestContainer.appendChild(zeroSpan);
}

function renderSearchUnavailable(message) {
  message = message || window.wordbookObject.searchUnavailableLabel;
  let unavailableText = d.createTextNode(message);
  suggestContainer.appendChild(unavailableText);
}

function showSuggestion() {
  window.requestAnimationFrame(() => {
    suggestContainer.classList.add(SHOW_CLASS);
  });
}

function hideSuggestion() {
  suggestContainer.classList.remove(SHOW_CLASS);
}

function clearSuggestion() {
  hideSuggestion();
  while (suggestContainer.firstChild)
    suggestContainer.removeChild(suggestContainer.firstChild);
}

function suggestionShown() {
  return suggestContainer.classList.contains(SHOW_CLASS);
}

function setExecuting(executing = true) {
  if (!executing) {
    searchContainer.classList.remove(EXECUTING_CLASS);
    return;
  }
  searchContainer.classList.add(EXECUTING_CLASS);
}

function setupProcessResultOnSearchButtonClick(result) {
  searchButtonClickHandler = () => {
    processResult(result);
  };
  searchButton.addEventListener("click", searchButtonClickHandler);
}

function clearProcessResultOnSearchButtonClick() {
  searchButton.removeEventListener("click", searchButtonClickHandler);
}

export default init;
